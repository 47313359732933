@import '~@angular/material/theming';
@import './extranet-palette';

// Plus imports for other components in your app.
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$extranet-app-primary: mat-palette($mat-paramedic-blue, 800);
$extranet-app-accent: mat-palette($mat-deep-orange, 800);
// The warn palette is optional (defaults to red).
$extranet-app-warn: mat-palette($mat-red);
// Create the theme object (a Sass map containing all of the palettes).
$extranet-app-theme: mat-light-theme($extranet-app-primary, $extranet-app-accent, $extranet-app-warn);

@mixin extranet-theme($theme) {
	/*@include ml-file-tree-theme(theme);*/
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($extranet-app-theme);
@include extranet-theme($extranet-app-theme);


